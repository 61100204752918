<script>
    export let imgUrl = "";
</script>

<div class="bg parallax">
    <div
        class="blurred"
        style={`background-image: url(${imgUrl});`} />
    <div
        class="normal"
        style={`background-image: url(${imgUrl});`} />
</div>
