<script>
    export let sectionTitle = "";
</script>

<section id={`${sectionTitle}`}>
    <h1 class="title">{sectionTitle}</h1>
    <div class="content">
        <slot />
    </div>
</section>
