<script>
    import { fade, fly } from "svelte/transition";
    import Section from "./Section.svelte";
    import ParallaxImg from "./ParallaxImg.svelte";

    const combId2Section = {
        "top-left": "Der Honig",
        "top-middle": "Der Markt",
        "top-right": "Social Media"
    };

    let focusedCombIds = [];

    const combFocus = combId => {
        const index = focusedCombIds.indexOf(combId);
        if (index === -1) {
            focusedCombIds = [...focusedCombIds, combId];
        }
    };
    const combBlur = combId => {
        const index = focusedCombIds.indexOf(combId);
        if (index !== -1) {
            focusedCombIds = [...focusedCombIds.filter((e, i) => i !== index)];
        }
    };
    const combClick = combId => {
        if (combId2Section.hasOwnProperty(combId)) {
            window.location.hash = combId2Section[combId];
        }
    };

    /* Maps-Function FROM https://medium.com/@colinlord/opening-native-map-apps-from-the-mobile-browser-afd66fbbb8a4 */
    function mapsUrl() {
        if (
            /* if we're on iOS, open in Apple Maps */
            navigator.platform.indexOf("iPhone") != -1 ||
            navigator.platform.indexOf("iPod") != -1 ||
            navigator.platform.indexOf("iPad") != -1
        ) {
            window.open(
                "maps://maps.google.com/maps/dir/?daddr=52.5435553548103,13.4174609184265"
            );
        } else {
            /* else use Google */
            window.open(
                "https://maps.google.com/maps/dir/?daddr=52.5435553548103,13.4174609184265"
            );
        }
    }

    const bgImageCount = 4;
    const bgImages = new Array(bgImageCount)
        .fill(null)
        .map((e, i) => `/images/bg_${i}.jpg`);
</script>

<div id="header">
    <div id="assistive-wrapper">
        <a
            on:click|preventDefault={e => combClick('top-left')}
            on:focus|preventDefault={e => combFocus('top-left')}
            on:blur|preventDefault={e => combBlur('top-left')}
            href="#Der Honig"
            title="Der Honig">
            <span>Der Honig</span>
        </a>
        <a
            on:click|preventDefault={e => combClick('top-middle')}
            on:focus|preventDefault={e => combFocus('top-middle')}
            on:blur|preventDefault={e => combBlur('top-middle')}
            href="#Der Markt"
            title="Der Markt">
            <span>Der Markt</span>
        </a>
        <a
            on:click|preventDefault={e => combClick('top-right')}
            on:focus|preventDefault={e => combFocus('top-right')}
            on:blur|preventDefault={e => combBlur('top-right')}
            href="#Social Media"
            title="Social Media">
            <span>Social Media</span>
        </a>
    </div>
    <svg
        id="header-logo"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 640 640">
        <defs>
            <path
                d="M320 352.68L260.92 384.68L260.92 448.68L320
                480.68L379.07 448.68L379.07 384.68L320 352.68Z"
                id="dcvqM2wLJ" />
            <path
                d="M320 480.26L289.77 463.26L259.55 446.26L380.45
                446.26L350.23 463.26L320 480.26ZM263.11 426.89L263.11
                407.53L376.89 407.53L376.89 426.89L263.11 426.89ZM263.11
                388.17L263.11 368.81L376.89 368.81L376.89 388.17L263.11
                388.17Z"
                id="c17QR14ixI" />
            <path
                d="M379.07 256.2L320 288.2L320 352.2L379.07 384.2L438.15
                352.2L438.15 288.2L379.07 256.2Z"
                id="d3hHBiZvv7" />
            <path
                d="M260.93 256.2L201.85 288.2L201.85 352.2L260.93
                384.2L320 352.2L320 288.2L260.93 256.2Z"
                id="c4lgjABoeS" />
            <path
                d="M438.15 159.97L379.07 191.97L379.07 255.97L438.15
                287.97L497.22 255.97L497.22 191.97L438.15 159.97Z"
                id="d4F8yB45XS" />
            <path
                d="M320 159.97L260.92 191.97L260.92 255.97L320
                287.97L379.07 255.97L379.07 191.97L320 159.97Z"
                id="a1zNjFDMI" />
            <path
                d="M201.85 159.97L142.78 191.97L142.78 255.97L201.85
                287.97L260.93 255.97L260.93 191.97L201.85 159.97Z"
                id="a25QjnCO8j" />
            <!-- prettier-ignore -->
            <text
                id="chzVi70CY"
                x="142.78"
                y="214.12"
                font-size="36"
                font-family="Oleo Script Swash Caps"
                font-weight="normal"
                font-style="normal"
                letter-spacing="0"
                alignment-baseline="before-edge"
                transform="matrix(1 0 0 1 73.89218749999995
                81.07843137254892)"
                style="line-height:100%"
                xml:space="preserve"
                dominant-baseline="text-before-edge">
                <!-- prettier-ignore -->
                <tspan
                    x="142.78"
                    dy="0em"
                    alignment-baseline="before-edge"
                    dominant-baseline="text-before-edge"
                    text-anchor="start">
                    Horny
                </tspan>
            </text>
            <!-- prettier-ignore -->
            <text
                id="m1K9JameG"
                x="142.78"
                y="214.12"
                font-size="36"
                font-family="Oleo Script Swash Caps"
                font-weight="normal"
                font-style="normal"
                letter-spacing="0"
                alignment-baseline="before-edge"
                transform="matrix(1 0 0 1 191.43281249999987
                81.07843137254892)"
                style="line-height:100%"
                xml:space="preserve"
                dominant-baseline="text-before-edge">
                <!-- prettier-ignore -->
                <tspan
                    x="142.78"
                    dy="0em"
                    alignment-baseline="before-edge"
                    dominant-baseline="text-before-edge"
                    text-anchor="start">
                    Honey
                </tspan>
            </text>
            <!-- prettier-ignore -->
            <text
                id="b2CEUaTkZV"
                x="166.47"
                y="214.12"
                font-size="20"
                font-family="Oleo Script Swash Caps"
                font-weight="normal"
                font-style="normal"
                letter-spacing="0"
                alignment-baseline="before-edge"
                transform="matrix(1 0 0 1 35.37968749999999
                -16.64379084967331)"
                style="line-height:100%"
                xml:space="preserve"
                dominant-baseline="text-before-edge">
                <!-- prettier-ignore -->
                <tspan
                    x="166.47"
                    dy="0em"
                    alignment-baseline="before-edge"
                    dominant-baseline="text-before-edge"
                    text-anchor="middle">
                    Der
                </tspan>
                <!-- prettier-ignore -->
                <tspan
                    x="166.47"
                    dy="1.2em"
                    alignment-baseline="before-edge"
                    dominant-baseline="text-before-edge"
                    text-anchor="middle">
                    Honig
                </tspan>
            </text>
            <!-- prettier-ignore -->
            <text
                id="hAsQ8Zvtv"
                x="169.81"
                y="214.12"
                font-size="20"
                font-family="Oleo Script Swash Caps"
                font-weight="normal"
                font-style="normal"
                letter-spacing="0"
                alignment-baseline="before-edge"
                transform="matrix(1 0 0 1 150.18593749999988
                -16.64379084967331)"
                style="line-height:100%"
                xml:space="preserve"
                dominant-baseline="text-before-edge">
                <!-- prettier-ignore -->
                <tspan
                    x="169.81"
                    dy="0em"
                    alignment-baseline="before-edge"
                    dominant-baseline="text-before-edge"
                    text-anchor="middle">
                    Der
                </tspan>
                <!-- prettier-ignore -->
                <tspan
                    x="169.81"
                    dy="1.2em"
                    alignment-baseline="before-edge"
                    dominant-baseline="text-before-edge"
                    text-anchor="middle">
                    Markt
                </tspan>
            </text>
            <!-- prettier-ignore -->
            <text
                id="f14GD29Xm2"
                x="169.87"
                y="214.12"
                font-size="20"
                font-family="Oleo Script Swash Caps"
                font-weight="normal"
                font-style="normal"
                letter-spacing="0"
                alignment-baseline="before-edge"
                transform="matrix(1 0 0 1 268.28124999999983
                -16.64379084967331)"
                style="line-height:100%"
                xml:space="preserve"
                dominant-baseline="text-before-edge">
                <!-- prettier-ignore -->
                <tspan
                    x="169.87"
                    dy="0em"
                    alignment-baseline="before-edge"
                    dominant-baseline="text-before-edge"
                    text-anchor="middle">
                    Social
                </tspan>
                <!-- prettier-ignore -->
                <tspan
                    x="169.87"
                    dy="1.2em"
                    alignment-baseline="before-edge"
                    dominant-baseline="text-before-edge"
                    text-anchor="middle">
                    Media
                </tspan>
            </text>
        </defs>
        <g>
            <g class="combs bottom">
                <g
                    id="comb-bottom"
                    class="comb"
                    on:mouseenter={e => combFocus('bottom')}
                    on:mouseleave={e => combBlur('bottom')}>
                    <use
                        xlink:href="#dcvqM2wLJ"
                        opacity="1"
                        fill="#eeae07"
                        fill-opacity="1" />
                    <g>
                        <use
                            xlink:href="#dcvqM2wLJ"
                            opacity="1"
                            fill-opacity="0"
                            stroke="#1e1307"
                            stroke-width="6"
                            stroke-opacity="1" />
                    </g>
                    <g class="stripes">
                        <use
                            xlink:href="#c17QR14ixI"
                            opacity="1"
                            fill="#1e1307"
                            fill-opacity="1" />
                    </g>
                </g>
            </g>
            <g class="combs middle">
                <g
                    id="comb-middle-right"
                    class="comb right"
                    on:mouseenter={e => combFocus('middle-right')}
                    on:mouseleave={e => combBlur('middle-right')}>
                    <use
                        xlink:href="#d3hHBiZvv7"
                        opacity="1"
                        fill="#eeae07"
                        fill-opacity="1" />
                    <g>
                        <use
                            xlink:href="#d3hHBiZvv7"
                            opacity="1"
                            fill-opacity="0"
                            stroke="#1e1307"
                            stroke-width="6"
                            stroke-opacity="1" />
                    </g>
                    <g id="b3yHR6HLno" class="text">
                        <use
                            xlink:href="#m1K9JameG"
                            opacity="1"
                            fill="#1e1307"
                            fill-opacity="1" />
                    </g>
                </g>
                <g
                    id="comb-middle-left"
                    class="comb left"
                    on:mouseenter={e => combFocus('middle-left')}
                    on:mouseleave={e => combBlur('middle-left')}>
                    <use
                        xlink:href="#c4lgjABoeS"
                        opacity="1"
                        fill="#eeae07"
                        fill-opacity="1" />
                    <g>
                        <use
                            xlink:href="#c4lgjABoeS"
                            opacity="1"
                            fill-opacity="0"
                            stroke="#1e1307"
                            stroke-width="6"
                            stroke-opacity="1" />
                    </g>
                    <g id="a3XNVadG5s" class="text">
                        <use
                            xlink:href="#chzVi70CY"
                            opacity="1"
                            fill="#1e1307"
                            fill-opacity="1" />
                    </g>
                </g>
            </g>
            <g class="combs top">
                <g
                    id="comb-top-right"
                    class="comb right clickable"
                    focused={focusedCombIds.indexOf('top-right') !== -1}
                    on:mouseenter={e => combFocus('top-right')}
                    on:mouseleave={e => combBlur('top-right')}
                    on:click={e => combClick('top-right')}
                    title="Social Media">
                    <use
                        xlink:href="#d4F8yB45XS"
                        opacity="1"
                        fill="#eeae07"
                        fill-opacity="1" />
                    <g>
                        <use
                            xlink:href="#d4F8yB45XS"
                            opacity="1"
                            fill-opacity="0"
                            stroke="#1e1307"
                            stroke-width="6"
                            stroke-opacity="1" />
                    </g>
                    <g id="b2nKaeJztY" class="text">
                        <use
                            xlink:href="#f14GD29Xm2"
                            opacity="1"
                            fill="#1e1307"
                            fill-opacity="1" />
                    </g>
                </g>
                <g
                    id="comb-top-middle"
                    class="comb middle clickable"
                    focused={focusedCombIds.indexOf('top-middle') !== -1}
                    on:mouseenter={e => combFocus('top-middle')}
                    on:mouseleave={e => combBlur('top-middle')}
                    on:click={e => combClick('top-middle')}
                    title="Der Markt">
                    <use
                        xlink:href="#a1zNjFDMI"
                        opacity="1"
                        fill="#eeae07"
                        fill-opacity="1" />
                    <g>
                        <use
                            xlink:href="#a1zNjFDMI"
                            opacity="1"
                            fill-opacity="0"
                            stroke="#1e1307"
                            stroke-width="6"
                            stroke-opacity="1" />
                    </g>
                    <g id="d44wpe76U" class="text">
                        <use
                            xlink:href="#hAsQ8Zvtv"
                            opacity="1"
                            fill="#1e1307"
                            fill-opacity="1" />
                    </g>
                </g>
                <g
                    id="comb-top-left"
                    class="comb left clickable"
                    focused={focusedCombIds.indexOf('top-left') !== -1}
                    on:mouseenter={e => combFocus('top-left')}
                    on:mouseleave={e => combBlur('top-left')}
                    on:click={e => combClick('top-left')}
                    title="Der Honig">
                    <use
                        xlink:href="#a25QjnCO8j"
                        opacity="1"
                        fill="#eeae07"
                        fill-opacity="1" />
                    <g>
                        <use
                            xlink:href="#a25QjnCO8j"
                            opacity="1"
                            fill-opacity="0"
                            stroke="#1e1307"
                            stroke-width="6"
                            stroke-opacity="1" />
                    </g>
                    <g id="b1J12QSdQa" class="text">
                        <use
                            xlink:href="#b2CEUaTkZV"
                            opacity="1"
                            fill="#1e1307"
                            fill-opacity="1" />
                    </g>
                </g>
            </g>
        </g>
    </svg>

    <ParallaxImg imgUrl={bgImages[0]} />
</div>
<div id="container">
    <main id="main">
        <article>
            <ParallaxImg imgUrl={bgImages[1]} />
            <Section sectionTitle={'Der Honig'}>
                <p>
                    <i>
                        Die Geschichte, warum wir das alles überhaupt
                        machen, lautet wie folgt:
                    </i>
                    <br />
                    Felix ist mit dem Honig vom Imker Herbert Reiß aus dem
                    kleinen Dorf Warthe nahe Templin groß geworden.
                    <br />
                    Und da dieser Imker schon seit über 30 Jahren imkert,
                    und eigentlich schon in Rente ist, hat Felix
                    beschlossen, den Verkauf in Berlin unter eigenem Label
                    zu organisieren.
                </p>
            </Section>

            <ParallaxImg imgUrl={bgImages[2]} />
            <Section sectionTitle={'Der Markt'}>
                <p>
                    Der Verkauf findet jeden Samstag von 9 Uhr bis 16 Uhr
                    auf dem Markt am Helmholtzplatz in Prenzlauer Berg statt
                    (auf der Lychener Straße).
                    <br />
                    Verkauft wird der Honig von Studierenden und Freunden
                    von Felix.
                    <br />
                    Ab 12 oder 13 Uhr gibt es zumeist auch
                    künstlerische Begleitung (normalerweise Musik).
                </p>
                <h5>
                    <a
                        href="https://maps.google.com/maps/dir/?q=52.5435553548103,13.4174609184265(Helmholtzplatz)"
                        on:click|preventDefault={mapsUrl}>
                        Maps
                    </a>
                </h5>
            </Section>

            <ParallaxImg imgUrl={bgImages[3]} />
            <Section sectionTitle={'Social Media'}>
                <p>
                    @hornyhoney_2019
                    <a
                        href="https://instagram.com/hornyhoney_2019"
                        target="_blank">
                        auf Instagram
                    </a>
                </p>
            </Section>
        </article>
    </main>
</div>
